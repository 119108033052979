import React, { FC } from 'react';
import { Grid, Header } from 'semantic-ui-react';
import Subtitle from 'components/Subtitle';
import FieldCheckbox from './FieldCheckbox';
import FieldText from './FieldText';
import { RegistrationDto } from 'types/common';
import { useFormikContext } from 'formik';

const Food: FC = () => {
    const { values } = useFormikContext<RegistrationDto>();

    return (
        <>
            <Subtitle title="Ruoka" />
            <p>Ruokaa on tarjolla seuraavasti:</p>
            <ul>
                <li>to iltaruoka</li>
                <li>pe aamupala, lounas ja illalla AsLAN Pizzataxi</li>
                <li>la aamupala</li>
            </ul>
            <p>
                Muiden kuin AsLAN Pizzataxin ruuat sisältyy ruokamaksuun. AsLAN Pizzataxin ruuat jokainen maksaa itse
                kortilla, lähimaksulla tai MobilePay:llä.
            </p>
            <p>Merkkaa myös ruokavaliot jos ostat ruuan.</p>
            <p>
                Jos järjestät jonkun peliturnauksen laneilla, saat ilmaisen ruuan. Ota yhteyttä Sampoon (sivun alussa
                yhteystiedot). Jos syystä tai toisesta et järjestäkkään turnausta, pyydämme maksamaan ruokailun.
            </p>
            <Header as="h4">AsLAN Pizzataxi</Header>
            <p>
                AsLAN Pizzataxi hakee perjantaina iltaruuan sovitusta listasta Kotipizzan ja Turkinpippurin tarjoamaa.
                Ruuat tulee tilata ja maksaa (kortti ja käteinen käy) päivän aikana järjestäjälle (tiedotetaan kyllä
                hyvin). Illalla sitten haetaan yhteistilaus ja tuodaan lanitilaan syötäväksi.
            </p>
            {/* <p>Ruokaa on tarjolla seuraavasti:</p>
            <ul>
                <li>to iltana lämmin ruoka</li>
                <li>pe aamupala, lounas ja lämmin iltaruoka</li>
                <li>la aamupala</li>
            </ul>
            <p>Ruuan hinta on 15 € koko ajalle. Jos on mukana vain osan aikaa, hinta on 10 €.</p>
            <p>Merkkaa myös ruokavaliot jos ostat ruuan.</p>
            <p>
                Jos järjestät jonkun peliturnauksen laneilla, saat ilmaisen ruuan. Ota yhteyttä Sampoon (sivun alussa
                yhteystiedot).
            </p> */}
            <Header as="h4">Haluan ostaa ruuan</Header>
            <Grid columns={2} stretched stackable>
                <Grid.Column width="16">
                    <FieldCheckbox name="ruoka" label="Kyllä" />
                </Grid.Column>
            </Grid>
            <Header as="h4">Ruokavalio, mikäli ostat ruuan</Header>
            <Grid columns={2} stretched stackable>
                <Grid.Column width="16">
                    <FieldCheckbox name="ruokaKaikki" label="Kaikki käy" />
                </Grid.Column>
                <Grid.Column width="16">
                    <FieldCheckbox name="ruokaLaktoositon" label="Laktoositon" />
                </Grid.Column>
                <Grid.Column width="16">
                    <FieldCheckbox name="ruokaGluteeniton" label="Gluteeniton" />
                </Grid.Column>
                <Grid.Column width="16">
                    <FieldCheckbox name="ruokaVegaani" label="Vegaani" />
                </Grid.Column>
                <Grid.Column width="2">
                    <FieldCheckbox name="ruokaMuu" label="Muu: " />
                </Grid.Column>
                <Grid.Column width="14">
                    <FieldText
                        name="ruokaMuuKommentti"
                        title=""
                        placeholder="Muuta"
                        size="mini"
                        style={{ width: 250 }}
                        disabled={!values.ruokaMuu}
                    />
                </Grid.Column>
            </Grid>
        </>
    );
};

export default Food;
