import React, { FC } from 'react';
import { Grid } from 'semantic-ui-react';
import Subtitle from 'components/Subtitle';
import FieldCheckbox from './FieldCheckbox';
import FieldText from './FieldText';
import { useFormikContext } from 'formik';
import { RegistrationDto } from 'types/common';

const Tournaments: FC = () => {
    const { values } = useFormikContext<RegistrationDto>();

    return (
        <>
            <Subtitle title="Turnaukset" />
            <p>
                Mihin turnauksiin haluaisit osallistua? Muutama turnaus järjestetään kiinnostuksen kohteiden mukaan.
                Turnauksiin voi ilmoittautua myös paikanpäällä.
            </p>
            <p>Muistathan asentaa ja päivittää pelit jo kotona!</p>
            <p>
                Vapaavalintaiseen kohtaan voit kirjoittaa toivepelit, katsotaan pystytäänkö niistä järjestämään
                turnausta. Vai järjestäisitkö sinä? Ole yhteydessä Sampoon, jos haluat järjestäjäksi.
            </p>
            <Grid columns={2} stretched stackable>
                <Grid.Column width="16">
                    <FieldCheckbox name="turnausBS" label="Eye of the Temple VR - VR-pelikone laneilla" />
                </Grid.Column>
                <Grid.Column width="16">
                    <FieldCheckbox name="turnausTetris" label="Tetris" />
                </Grid.Column>
                <Grid.Column width="16">
                    <FieldCheckbox name="turnausTableTennis" label="Pingis (fyysinen)" />
                </Grid.Column>
                <Grid.Column width="16">
                    <FieldCheckbox name="turnausCS" label="CS" />
                </Grid.Column>
                <Grid.Column width="16">
                    <FieldCheckbox name="turnausOW" label="LoL ARAM" />
                </Grid.Column>
                <Grid.Column width="16">
                    <FieldCheckbox name="turnausBiljari" label="MS Paint - taidetta" />
                </Grid.Column>
                <Grid.Column width="2">
                    <FieldCheckbox name="turnausMuuta" label="Muuta: " />
                </Grid.Column>
                <Grid.Column width="14">
                    <FieldText
                        name="turnausMuutaKommentti"
                        title=""
                        placeholder="Muuta"
                        size="mini"
                        style={{ width: 250 }}
                        disabled={!values.turnausMuuta}
                    />
                </Grid.Column>
            </Grid>
        </>
    );
};

export default Tournaments;
